<template>
  <div class="relative p-normal pb-14">
    <Logo></Logo>
    <a-scroll />
    <div class="terms">
      <h2>Condiciones de Uso y Contratación</h2>
      <div class="politics-terms-container">
        <small>Última actualización: 31 de mayo de 2021</small>
        <ol>
          <li>
            <h2>- Titularidad</h2>
            Este sitio es propiedad y está operado por Grupo Gastrojoyas S.L. (en adelante  SoloFoodies), con domicilio en Calle de Huelva, 16 - LOFT 50, CP 28100, Alcobendas,
            Madrid.
            <br><br>
            Grupo Gastrojoyas S.L. inscrita en el Registro Mercantil de Madrid, al folio 50, tomo 39775, inscripción 1 y hoja M-706751.
            <br><br>
            NIF: B88487327<br>
            Correo electrónico: <a class="text-primary" href="mailto:info@solofoodies.com" target="_blank">info@solofoodies.com</a>
          </li>
          <li>
            <h2>- Aceptación</h2>
            Este sitio web y el servicio proporcionado (en adelante el Servicio) está disponible para cualquier usuario y está sujeto a los siguientes términos y condiciones:
            estas Condiciones de Uso y Contratación, nuestra Política de Privacidad y Política de Cookies, que además estarán siempre disponibles en el pie del sitio online y
            no deberá ser utilizado con fines ilícitos ni en modo distinto a lo contemplado en las mismas.
            <br><br>
            Cuando usas el Servicio aceptas nuestros términos y condiciones. Con ello te comprometes a no usar este sitio para fines ilegales.
            <br><br>
            Por otro lado, te informamos que por motivos legales archivamos los documentos electrónicos en que quedan formalizadas las contrataciones de algunos de nuestros
            servicios. Podrás acceder a dichos documentos en cualquier momento solicitándolo en:
            <a class="text-primary" href="mailto:info@solofoodies.com" target="_blank">info@solofoodies.com</a>
          </li>
          <li>
            <h2>- Descripción del Servicio</h2>
            <ol>
              <li>
                <b>- Prestación</b><br>
                A través del Servicio se ofrece la posibilidad de conectar a influencers y negocios para conseguir una mayor difusión y repercusión de sus productos, servicios o eventos. En este sentido, puedes darte de alta en nuestra plataforma como negocio o como influencer.<br><br>
                Si usas el Servicio como negocio deberás crear tu perfil a través de nuestro formulario de registro “Soy un negocio” donde podrás subir, gestionar y analizar las diferentes colaboraciones que necesites para tu establecimiento.<br><br>
                Si usas el Servicio como influencer deberás crear tu perfil a través del mismo formulario de registro que el utilizado por los negocios pero, en este caso, en el apartado “Soy un foodie” donde podrás visualizar las diferentes colaboraciones gratuitas y de pago, conseguir descuentos o invitaciones gratuitas y promocionar el establecimiento en tus redes sociales.<br><br>
                El proceso de contratación y la gestión del Servicio por parte de la plataforma será la siguiente:<br><br>
                <ul class="alphabet_ul">
                  <li>
                    Los usuarios registrados como negocios podrán publicar uno o varios anuncios de colaboración para la captación de influencers a fin de promocionar sus
                    establecimientos, en ellos podrá indicar el rango de fechas o fecha exacta, el tipo de descuento en porcentaje o crédito (que en ningún caso será abonado
                    al influencer si no se consume en su totalidad), el número máximo de comensales que pueden acompañar al influencer y la disponibilidad (lun-jue o toda la
                    semana).
                    <br><br>
                    También podrán invitar directamente a aquellos influencers que estén interesados en colaborar con el establecimiento.
                  </li>
                  <li>
                    Los usuarios registrados como influencers podrán encontrar las diferentes colaboraciones publicadas y podrán suscribirse a aquellas que más les interesen,
                    también podrán proponerse para realizar una colaboración dando me gusta a los negocios con los que quieran colaborar.
                  </li>
                  <li>
                    El negocio aceptará a aquellos influencers que más se adapten a las exigencias de la colaboración y a la propia filosofía y estética del negocio y que hayan
                    mostrado interés en dicha colaboración.
                    <br><br>
                    El negocio se reserva el derecho de poder rechazar o limitar las consumiciones o solicitudes de los influencers cuando excedan del valor o características
                    establecidas previamente en la colaboración
                  </li>
                  <li>
                    El registro de negocios e influencers en la plataforma será gratuito. Por otro lado, el negocio pagará al influencer por la publicidad que realice del
                    local a través de un descuento en porcentaje o crédito sobre el importe del gasto realizado en el establecimiento.
                  </li>
                  <li>
                    El influencer deberá publicitar el establecimiento a través de sus redes sociales y visibilizar la colaboración realizada con el negocio.
                    <br><br>
                    En el caso de que el influencer decida no publicar, estará obligado a valorar la colaboración completando el formulario habilitado para
                    ello dentro de la plataforma  donde indicar la razón por la que no ha realizado dicha publicación.
                  </li>
                  <li>
                    Una vez finalizada la prestación por el negocio, éste podrá valorar al influencer de manera privada. Del mismo modo, el influencer podrá
                    valorar también al negocio.
                  </li>
                </ul>
              </li>
              <li>
                <b>- Uso</b><br>
                Los usuarios, ya sean negocios o influencers, se comprometen a usar el Servicio de acuerdo a la legislación vigente y los términos y condiciones de la plataforma.
                <br><br>
                Igualmente, los usuarios se comprometen a no recabar datos con finalidad publicitaria, remitir publicidad de cualquier clase o comunicaciones con fines de venta
                u otras de naturaleza comercial. Tampoco podrá poner a disposición de terceros, con cualquier finalidad, datos recabados en el Servicio.
                <br><br>
                En caso de incumplir estas obligaciones, los usuarios deberán responder frente a aquéllos. De igual forma en caso de dañar, inutilizar, sobrecargar, deteriorar
                o impedir la normal utilización de los materiales e información contenidos en el Servicio, los sistemas de información o los documentos, archivos y toda clase
                de contenidos almacenados en cualquier equipo informático del Servicio, de sus miembros o de cualquier usuario del Servicio.
              </li>
            </ol>
          </li>
          <li>
            <h2>- Enlaces externos</h2>
            Puede que desde el Servicio se te remita a otros sitios web a través de enlaces.
            <br><br>
            Sin embargo, SoloFoodies no tiene ningún control sobre esos sitios o su contenido, sujetos a sus propios términos y condiciones. Por tanto, SoloFoodies no es
            responsable de la calidad, veracidad o exactitud de la información contenida en aquellos.
          </li>
          <li>
            <h2>- Edad</h2>
            En cuanto a la contratación de nuestro Servicio, declaras que eres mayor de edad y que dispones de la capacidad legal necesaria para vincularte por este
            acuerdo y utilizar el sitio de conformidad con sus términos y condiciones, que comprendes y reconoces en su totalidad.
            <br><br>
            Si contratas en nombre de una empresa el Servicio, reconoces disponer de autorización y la representación adecuada en nombre de la organización para ello.
            <br><br>
            Declaras que toda la información que proporciones para acceder al Servicio, antes y durante su utilización, es verdadera, completa y precisa.
          </li>
          <li>
            <h2>- Propiedad intelectual e industrial</h2>
            El contenido y la información del Servicio (entre otros datos, texto, sonido, imagen o código informático), así como los elementos de hardware o software
            utilizados para proporcionar tal contenido e información, son propiedad de SoloFoodies o bien dispone de las autorizaciones correspondientes para su uso.
            <br><br>
            Además, se prohíbe la modificación, reproducción, duplicación, copia, distribución, venta, reventa y demás formas de explotación para fines comerciales o
            equivalentes del Servicio.
            <br><br>
            Para cualquier otro uso del contenido del Servicio necesitas nuestro consentimiento previo por escrito.
          </li>
          <li>
            <h2>- Contenido de usuario</h2>
            <ol>
              <li>
                <b>- Contenido</b>
                Puedes contribuir al Servicio enviándonos mensajes a nuestra dirección de correo electrónico y también enviándonos información sobre las colaboraciones que
                quieras promocionar a través de la plataforma (en adelante “Contenido”).
                <br><br>
                Podemos usar tu Contenido de distintas maneras, tales como: mostrarlo en el sitio web, reformatearlo, traducirlo a otros idiomas, editarlo para aportar
                claridad, corregir errores, promocionarlo o distribuirlo.
                <br><br>
                Por tanto, al enviarnos Contenido concedes a SoloFoodies una licencia de uso mundial, no exclusiva, gratuita, hasta la retirada del contenido, transferible
                y sublicenciable sobre ese Contenido.
                <br><br>
                Eso significa que el contenido sigue siendo tuyo, pero SoloFoodies, gracias a esa licencia de uso, puede: <b>a)</b> usar, reproducir, modificar, adaptar, traducir,
                distribuir y publicar el Contenido, crear obras derivadas a partir de él, mostrarlo y exhibirlo en todo el mundo, por cualquier medio conocido y para
                cualquier fin legítimo; y <b>b)</b> usar el nombre que envíes en relación con ese Contenido.
                <br><br>
                No obstante, SoloFoodies se reserva el derecho de no publicar aquellos contenidos o informaciones que sean falsas o contrarias a los derechos de terceros.
              </li>
              <li>
                <b>- Actividades prohibidas</b><br>
                Además, te comprometes a no realizar ninguno de los actos siguientes:<br><br>
                <ul class="alphabet_ul">
                  <li>
                    Acceder, supervisar o copiar contenido o información de este sitio web mediante el uso de robots, rastreadores, raspadores de datos o cualquier otro
                    medio automatizado que permitan acceder, rastrear, indexar, recuperar o utilizar de cualquier otro modo el sitio web o su contenido para cualquier
                    propósito sin el permiso explícito por escrito de SoloFoofies;
                  </li>
                  <li>
                    Insertar, reflejar o incorporar de algún otro modo una parte de este sitio web en otro sin nuestra autorización previa por escrito;
                  </li>
                  <li>
                    Intentar modificar, traducir, adaptar, editar, descompilar, desensamblar o aplicar ingeniería inversa a ningún programa de software que SoloFoodies
                    utilice en relación con el sitio web;
                  </li>
                  <li>
                    Usar el sitio web para amenazar, acosar, defraudar, incitar, hostigar a otras personas o defender el hostigamiento de otra persona, o interferir de
                    cualquier otro modo con el uso del sitio web por parte de otro usuario;
                  </li>
                  <li>
                    Usar el sitio web para enviar o transmitir correo no deseado (“spam”), cadenas de mensaje, concursos, “correo basura”, sistemas piramidales, encuestas
                    u otros mensajes masivos, ya sean de naturaleza comercial o no;
                  </li>
                  <li>
                    Utilizar el sitio web para infringir derechos de terceros, lo que incluye el quebrantamiento de la confianza y la infracción de copyright, marcas comerciales,
                    patentes, secretos comerciales, derechos morales, derechos de privacidad o cualesquiera otros derechos de propiedad intelectual o de propiedad;
                  </li>
                  <li>
                    Intentar obtener acceso no autorizado al sitio web, los sistemas informáticos o las redes conectadas al sitio web mediante prácticas de piratería informática,
                    extracción de contraseñas o cualquier otro medio. Así como la transmisión de virus informáticos, gusanos, defectos, troyanos u otros elementos de naturaleza
                    destructiva;
                  </li>
                  <li>
                    Utilizar algún dispositivo, software o rutina que interfiera con el funcionamiento correcto y las medidas de seguridad del sitio web.
                  </li>
                </ul>
              </li>
              <li>
                <b>- Valoraciones</b><br>
                SoloFoodies permite a los negocios valorar la colaboración con los influencers y viceversa, los comentarios de estas valoraciones no serán públicos pero sí
                la puntuación de los mismos, únicamente ayudarán al Servicio a mejorar las posiciones del directorio permitiendo mostrar en primer lugar a aquellos foodies
                con mejor rendimiento.
                <br><br>
                Estas valoraciones también están sujetas a estas Condiciones de Uso y Contratación. Como usuario del servicio serás el único responsable del uso que hagas
                de esta opción y la utilizarás por tu cuenta y riesgo. SoloFoodies no editará ni controlará las valoraciones de los usuarios ni asumirá responsabilidad ni
                obligación alguna por dichos mensajes de los usuarios, más allá de lo previsto legalmente.
                <br><br>
                No obstante, SoloFoodies se reserva el derecho de poder eliminar las valoraciones y el contenido de los usuarios. No están permitidas las valoraciones cuyo
                contenido sea falso, ilegal, engañoso, injurioso, difamatorio, obsceno, pornográfico, indecente, acosador, intimidatorio, contrario a los derechos de
                privacidad o publicidad, abusivo o fraudulento.
                <br><br>
                En todo caso, recuerda que al acceder o usar el Servicio podrás verte expuesto a contenido de otros usuarios que podría ser ofensivo, obsceno, impreciso,
                censurable o inadecuado por cualquier otra razón. SoloFoodies no suscribe dicho contenido ni puede refrendar su precisión.
              </li>
            </ol>
          </li>
          <li>
            <h2>- Precio y pago</h2>
            El registro en el Servicio es gratuito. En todo caso, para cualquier aclaración, incidencia o reclamación, puedes contactar con nosotros en el siguiente email:
            <a class="text-primary" href="mailto:info@solofoodies.com" target="_blank">info@solofoodies.com</a>
          </li>
          <li>
            <h2>- Negociaciones entre negocio e influencer</h2>
            SoloFoodies no intervendrá en ningún caso en la colaboración entre el negocio y el influencer, permaneciendo al margen de las mismas en todo momento, por lo
            que no será responsable, en ningún caso, del cumplimiento de las obligaciones de uno con otro.
            <br><br>
            Por tanto, el negocio y el influencer eximen a SoloFoodies de cualquier desavenencia que pudiera surgir entre ambos.
            <br><br>
            En todo caso, las obligaciones de las partes (el negocio y el influencer) al realizar las colaboraciones publicitadas en la plataforma serán aplicables
            exclusivamente a los mismos, sin derivar ninguna otra obligación a SoloFoodies salvo las descritas en nuestros términos y condiciones.
            <br><br>
            En este sentido, serán plenamente responsables las partes respecto a cualquier actuación que se pueda derivar de dicha colaboración.
            <br><br>
            En ningún caso SoloFoodies controlará ni intervendrá en la ejecución de las tareas y servicios por parte del negocio y el influencer, ni en la adecuación,
            calidad o resultado de las mismas. Por ello, SoloFoodies excluye, con toda la extensión permitida por el ordenamiento jurídico, cualquier responsabilidad
            por los daños y perjuicios de toda naturaleza que puedan deberse a la ejecución o resultado de la labor publicitaria encomendada al influencer.
            <br><br>
            A su vez, el negocio expresamente exime a SoloFoodies de cualquier discrepancia, responsabilidad, daño, perjuicio o menoscabo como resultado de la contratación
            o del trabajo realizado por el influencer.
          </li>
          <li>
            <h2>- Exclusión de garantías y responsabilidad</h2>
            Salvo en aquellos casos expresamente descritos en los presentes términos y condiciones y hasta donde la ley permita, SoloFoodies no se responsabiliza por los daños
            y perjuicios de cualquier naturaleza que puedan deberse a la falta de exactitud, exhaustividad o actualidad, incluidos errores y omisiones, de la información contenida
            en el Servicio. De igual forma, tampoco de cualquier deber o compromiso de verificar o vigilar sus contenidos e informaciones.
            <br><br>
            Igualmente, SoloFoodies no garantiza la disponibilidad y continuidad del funcionamiento de los Servicios. SoloFoodies procurará advertir con suficiente antelación
            de las interrupciones que pudieran suceder en el funcionamiento del Servicio siempre que ello sea posible.
            <br><br>
            SoloFoodies excluye, con toda la extensión permitida por el ordenamiento jurídico, cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan
            deberse a la falta de disponibilidad o de continuidad del funcionamiento del Servicio. De igual manera, respecto a la defraudación de la utilidad que los usuarios
            hubiesen podido atribuir al Servicio.
            <br><br>
            Asimismo, SoloFoodies excluye cualquier responsabilidad por los daños y perjuicios de toda naturaleza que pudieran deberse a la utilización del Servicio y de sus
            contenidos por parte de los usuarios, el negocio y el influencer, o que puedan deberse a la falta de veracidad, vigencia o autenticidad de la información que los
            usuarios proporcionan a otros acerca de sí mismos. En particular, SoloFoodies excluye cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan
            deberse a la suplantación de la personalidad de un tercero efectuada por un usuario en cualquier clase de comunicación realizada a través del Servicio.
          </li>
          <li>
            <h2>- Eliminar cuenta</h2>
            El servicio no verifica ni autentifica la identidad de cada una de las cuentas que los usuarios pueden crear dentro de la plataforma.<br><br>
            Como usuario, no puedes hacerte pasar por otra persona ni crear una cuenta en nombre de alguien más, a menos que cuentes con su permiso expreso. Por ello, si nosotros
            detectamos este hecho o una tercera persona nos lo comunica, el Servicio, se reserva el derecho a proceder a la suspensión de dicha cuenta sin previo aviso.
            <br><br>
            Tras esto, se eliminará toda la información y contenido del perfil del usuario y se cancelarán automáticamente las colaboraciones que estuvieran en vigor en ese
            momento.
            <br><br>
            Si como usuario de la cuenta suspendida y cancelada no estás de acuerdo con esta decisión y quieres formular alegaciones al respecto, puedes ponerte en contacto con
            nosotros a través de: <a class="text-primary" href="mailto:info@solofoodies.com" target="_blank">info@solofoodies.com</a>
          </li>
          <li>
            <h2>- Seguridad</h2>
            Como se ha indicado anteriormente, todo el procedimiento así como la transmisión de tus datos personales, se efectúa en una página segura y de forma cifrada a través
            del protocolo TLS.
            <br><br>
            Garantizamos la seguridad del Servicio conforme a los presentes conocimientos tecnológicos. Sin embargo, SoloFoodies no puede garantizar la total seguridad futura del
            Servicio. En todo caso, sí nos comprometemos a subsanar y a implementar las medidas correctoras oportunas para corregir un posible fallo de seguridad lo antes posible.
            <br><br>
            Te comprometes a notificar a SoloFoodies, de forma inmediata y a través del email <a class="text-primary" href="mailto:info@solofoodies.com" target="_blank">info@solofoodies.com</a>,
            cualquier situación que pudiera conducir a la suplantación de la identidad de un usuario
          </li>
          <li>
            <h2>- Modificaciones y nulidad</h2>
            Podremos actualizar los términos y condiciones del Servicio en el futuro, así como las características y funciones del mismo, sin afectar negativamente a su calidad.
            <br><br>
            Te informaremos sobre los cambios en los términos y condiciones colocando un aviso en un lugar prominente de nuestra web y/o por correo electrónico.
            <br><br>
            Si cualquier cláusula incluida en nuestros términos y condiciones fuese declarada, total o parcialmente, nula o ineficaz, la misma solo afectará a dicha disposición
            o a la parte de la misma que resulte nula o ineficaz. Subsistirán los términos y condiciones en todo lo demás.
          </li>
          <li>
            <h2>- Reclamaciones y acciones derivadas del contrato</h2>
            Este Servicio se rige por la legislación española.
            <br><br>
            En todo caso, con el fin de simplificar la resolución de reclamaciones por la vía civil y reducir costes, no excluimos la posibilidad de someternos ante un Arbitraje
            de Equidad de la Corte de Arbitraje de las Cámaras de Comercio e Industria.
            <br><br>
            En este sentido, y según la normativa aplicable, SoloFoodies informa de la existencia de una plataforma europea de resolución de litigios en línea que facilita la
            resolución extrajudicial de dichos litigios para contratos celebrados igualmente en línea entre consumidores y prestadores de servicios de Internet. A dicha plataforma
            se podrá acceder a través de la siguiente página web: <a class="text-primary" href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage" target="_blank">http://ec.europa.eu/odr</a>
            <br><br>
            Si la contratación del Servicio la realizara una empresa, en caso de controversia las partes se someten a los tribunales de Madrid y a la legislación española.
          </li>
          <li>
            <h2>- Contacto</h2>
            Si tienes dudas sobre estos términos y condiciones, contacta con nosotros en:
            <br><br>
            E-mail: <a class="text-primary" href="mailto:info@solofoodies.com" target="_blank">info@solofoodies.com</a><br>
            Dirección: Calle de Huelva, 16 - LOFT 50, CP 28100, Alcobendas, Madrid
          </li>
        </ol>
      </div>
    </div>
  </div>
<!--  <Footer></Footer>-->
</template>

<script>
import Logo from '@/components/Logo'
// import Footer from '@/components/Footer'

export default {
  name: 'Terms',
  components: {
    Logo
  }
}
</script>

<style scoped>

</style>
